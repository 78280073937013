import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Context as AuthContext } from '../../../context/AuthContext';
import passwordSchema from '../password';

const ForgotPasswordConfirm = () => {
  const {
    state: authState,
    login,
    clearError,
  } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [modal, setModal] = useState(true);

  useEffect(() => {
    if (authState.user.signInUserSession) {
      clearError();
      setModal(!modal);
      history.push('/authhome');
    }
  }, [authState.user]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
    password: Yup.string()
      .test('is-password', t('passwordRequirement'), (value) => passwordSchema.validate(value)),
  });

  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {
      history.push('/');
    }
  };

  const submit = (values) => {
    login(values);
  };

  const signUp = () => {
    history.push('/signup');
    setModal(!modal);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('loginTitle')}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              handleChange, handleBlur, handleSubmit, touched, values, errors,
            }) => (
              <Form>
                <FormGroup>
                  <Label for="Email">{t('email')}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="Email"
                    placeholder="example@email.com"
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    invalid={errors.email && touched.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="Password">{t('password')}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="Password"
                    autoComplete="off"
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    invalid={errors.password && touched.password}
                  />
                  <FormFeedback>{errors.password}</FormFeedback>
                </FormGroup>
                <FormGroup className="mt-2">
                  <Button color="link" onClick={signUp}>{t('signupTitle')}</Button>
                </FormGroup>
                <FormGroup className="float-end mt-4">
                  <Button color="primary" onClick={handleSubmit}>{t('login')}</Button>
                  {' '}
                  <LinkContainer to="/">
                    <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                  </LinkContainer>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ForgotPasswordConfirm;
