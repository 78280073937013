import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Context as AuthContext } from '../../../context/AuthContext';
import passwordSchema from '../password';

const SignUp = () => {
  const {
    state: authState,
    signUp,
    clearError,
  } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [modal, setModal] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (authState.user.userSub) {
      clearError();
      setModal(!modal);
      history.push('/authhome');
    }
  }, [authState.user]);

  useEffect(() => {
    if (authState.signUpConfirm) {
      history.push('/signupconfirm');
    }
  }, [authState.signUpConfirm]);

  const validationSchema = Yup.object().shape({
    givenName: Yup.string().required(t('required')),
    familyName: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
    password: Yup.string()
      .test('is-password', t('passwordRequirement'), (value) => passwordSchema.validate(value)),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], t('passwordMatch')),
  });

  const toggle = () => {
    setModal(!modal);
    if (modal === false) {
      clearError();
      history.push('/');
    }
  };

  const submit = (values) => {
    if (showMessage) {
      clearError();
      setShowMessage(true);
      signUp(values);
    } else {
      setModal(!modal);
      history.push('/signupconfirm');
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('signupTitle')}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              email: '',
              password: '',
              passwordConfirm: '',
              givenName: '',
              familyName: '',
              phone: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              handleChange, handleBlur, handleSubmit, touched, values, errors,
            }) => (
              <Form>
                <FormGroup>
                  <Label for="givenName">{t('firstName')}</Label>
                  <Input
                    type="text"
                    name="givenName"
                    id="givenName"
                    value={values.givenName}
                    onChange={handleChange('givenName')}
                    onBlur={handleBlur('givenName')}
                    invalid={errors.givenName && touched.givenName}
                  />
                  <FormFeedback>{errors.givenName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="familyName">{t('familyName')}</Label>
                  <Input
                    type="text"
                    name="familyName"
                    id="familyName"
                    value={values.familyName}
                    onChange={handleChange('familyName')}
                    onBlur={handleBlur('familyName')}
                    invalid={errors.familyName && touched.familyName}
                  />
                  <FormFeedback>{errors.familyName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="phone">{t('phone')}</Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    value={values.phone}
                    onChange={handleChange('phone')}
                    onBlur={handleBlur('phone')}
                    invalid={errors.phone && touched.phone}
                  />
                  <FormFeedback>{errors.phone}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="Email">{t('email')}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="Email"
                    placeholder="example@email.com"
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    invalid={errors.email && touched.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="Password">{t('password')}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="Password"
                    autoComplete="off"
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    invalid={errors.password && touched.password}
                  />
                  <FormFeedback>{errors.password}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="passwordConfirm">{t('passwordConfirm')}</Label>
                  <Input
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    autoComplete="off"
                    value={values.passwordConfirm}
                    onChange={handleChange('passwordConfirm')}
                    onBlur={handleBlur('passwordConfirm')}
                    invalid={errors.passwordConfirm && touched.passwordConfirm}
                  />
                  <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                </FormGroup>
                <FormGroup className="float-end mt-4">
                  {showMessage
                    ? <Button color="primary" onClick={handleSubmit}>{t('continue')}</Button>
                    : (
                      <>
                        <Button color="primary" onClick={handleSubmit}>{t('signup')}</Button>
                        {' '}
                        <LinkContainer to="/">
                          <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                        </LinkContainer>
                      </>
                    )}
                </FormGroup>
                {showMessage && !authState.errorMessage
                  ? (
                    <FormGroup>
                      <Alert color="success">{t('signupMessage')}</Alert>
                    </FormGroup>
                  )
                  : <></>}
                <FormGroup>
                  {authState.errorMessage
                    ? <Alert color="danger">{authState.errorMessage}</Alert>
                    : <></>}
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SignUp;
