import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const ProtectedRoute = (Comp, route = '/') => (props) => {
  const history = useHistory();

  async function checkAuthState() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      history.push(route);
    }
  }
  useEffect(() => {
    checkAuthState();
  });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (<Comp {...props} />);
};

export default ProtectedRoute;
