/* eslint-disable arrow-body-style */
import { Auth } from 'aws-amplify';

const localConfig = {
  storage: {
    bucket: 'dev-subscriptionz-api-service',
  },
  api: {
    region: 'ca-central-1',
    url: 'http://localhost:49915/',
  },
  cognito: {
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_xMHcUV7iC',
    appClientId: '1vmaoonveuavsvrv6acbq4366n',
    identityPoolId: 'ca-central-1:d443c467-99dd-4b52-8c84-7dbc1ce510f2',
  },
};

const devConfig = {
  storage: {
    bucket: 'dev-subscriptionz-api-service',
  },
  api: {
    region: 'ca-central-1',
    url: 'https://dev.api.subscriptionz.ca/',
  },
  cognito: {
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_xMHcUV7iC',
    appClientId: '1vmaoonveuavsvrv6acbq4366n',
    identityPoolId: 'ca-central-1:d443c467-99dd-4b52-8c84-7dbc1ce510f2',
  },
};
const sandboxConfig = {
  storage: {
    bucket: 'sandbox-subscriptionz-api-service',
  },
};

const prodConfig = {
  storage: {
    bucket: 'subscriptionz-api-service',
  },
};

let config = devConfig;
if (process.env.REACT_APP_STAGE === 'prod') {
  config = prodConfig;
} else if (process.env.REACT_APP_STAGE === 'sandbox') {
  config = sandboxConfig;
} else if (process.env.REACT_APP_STAGE === 'local') {
  config = localConfig;
}
config.env = process.env.REACT_APP_STAGE;

const API = {
  endpoints: [
    {
      name: 'User',
      endpoint: `${config.api.url}/v1/user`,
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        };
      },
    },
  ],
};
config.API = API;
config.MAX_ATTACHMENT_SIZE = 5000000;
const exportConfig = config;

export default exportConfig;
