import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Context as AuthContext } from '../../../context/AuthContext';
import passwordSchema from '../../Auth/password';

const Password = () => {
  const {
    // eslint-disable-next-line no-unused-vars
    state: authState,
    // eslint-disable-next-line no-unused-vars
    clearError,
  } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [modal, setModal] = useState(true);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .test('is-password', t('passwordRequirement'), (value) => passwordSchema.validate(value)),
    newPassword: Yup.string()
      .test('is-password', t('passwordRequirement'), (value) => passwordSchema.validate(value)),
    passwordConfirm: Yup.string().oneOf([Yup.ref('newPassword'), null], t('passwordMatch')),
  });

  const toggle = () => {
    setModal(!modal);
    if (modal === false) {
      history.push('/authhome');
    }
  };

  const submit = (values) => {
    console.log(values);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('accountPassword')}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              password: '',
              newPassword: '',
              passwordConfirm: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              handleChange, handleBlur, handleSubmit, touched, values, errors,
            }) => (
              <Form>
                <FormGroup>
                  <Label for="newPassword">{t('currentPassword')}</Label>
                  <Input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    autoComplete="off"
                    value={values.newPassword}
                    onChange={handleChange('newPassword')}
                    onBlur={handleBlur('newPassword')}
                    invalid={errors.newPassword && touched.newPassword}
                  />
                  <FormFeedback>{errors.newPassword}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="Password">{t('newPassword')}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="Password"
                    autoComplete="off"
                    value={values.password}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    invalid={errors.password && touched.password}
                  />
                  <FormFeedback>{errors.password}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="passwordConfirm">{t('passwordConfirm')}</Label>
                  <Input
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    autoComplete="off"
                    value={values.passwordConfirm}
                    onChange={handleChange('passwordConfirm')}
                    onBlur={handleBlur('passwordConfirm')}
                    invalid={errors.passwordConfirm && touched.passwordConfirm}
                  />
                  <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                </FormGroup>
                <FormGroup className="float-end mt-4">
                  <Button color="primary" onClick={handleSubmit}>{t('continue')}</Button>
                  {' '}
                  <LinkContainer to="/">
                    <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                  </LinkContainer>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Password;
