import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Context as AuthContext } from '../../../context/AuthContext';

const ForgotPassword = () => {
  const {
    state: authState,
    forgotPassword,
    clearError,
  } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [modal, setModal] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
  });

  const toggle = () => {
    setModal(!modal);
    if (modal === false) {
      history.push('/');
    }
  };

  const submit = (values) => {
    if (showMessage === false) {
      clearError();
      setShowMessage(true);
      forgotPassword(values);
    } else {
      setModal(!modal);
      history.push('/forgotpasswordconfirm');
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('forgotPassword')}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              handleChange, handleBlur, handleSubmit, touched, values, errors,
            }) => (
              <Form>
                <FormGroup>
                  <Label for="Email">{t('email')}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="Email"
                    placeholder="example@email.com"
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    invalid={errors.email && touched.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup className="float-end mt-4">
                  {showMessage
                    ? <Button color="primary" onClick={handleSubmit}>{t('continue')}</Button>
                    : (
                      <>
                        <Button color="primary" onClick={handleSubmit}>{t('continue')}</Button>
                        {' '}
                        <LinkContainer to="/">
                          <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                        </LinkContainer>
                      </>
                    )}
                </FormGroup>
                {showMessage && !authState.errorMessage
                  ? (
                    <FormGroup>
                      <Alert color="success">{t('signupMessage')}</Alert>
                    </FormGroup>
                  )
                  : <></>}
                <FormGroup>
                  {authState.errorMessage
                    ? <Alert color="danger">{authState.errorMessage}</Alert>
                    : <></>}
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ForgotPassword;
