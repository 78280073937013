import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Container } from 'reactstrap';

import { Provider as AuthProvider } from './context/AuthContext';
import config from './config';
import NavBar from './components/NavBar';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/Home';
import Login from './pages/Auth/Login';
import SignUp from './pages/Auth/SignUp';
import SignUpConfirm from './pages/Auth/SignUpConfirm';
import AuthHome from './pages/Auth/Home';
import AccountSettings from './pages/Account/Settings';
import Password from './pages/Account/Password';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ForgotPasswordConfirm from './pages/Auth/ForgotPasswordConfirm';

Amplify.Logger.LOG_LEVEL = 'ERROR';
Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.identityPoolId,
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    mandatorySignIn: true,
  },
  Storage: {
    bucket: config.storage.bucket,
  },
  API: config.api,
});

function App() {
  return (
    <Container>
      <AuthProvider>
        <Router>
          <NavBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/signupconfirm" component={SignUpConfirm} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/forgotpasswordconfirm" component={ForgotPasswordConfirm} />
            <Route exact path="/authhome" component={ProtectedRoute(AuthHome)} />
            <Route exact path="/accountsettings" component={ProtectedRoute(AccountSettings)} />
            <Route exact path="/changepassword" component={ProtectedRoute(Password)} />
          </Switch>
        </Router>
      </AuthProvider>
    </Container>
  );
}

export default App;
