import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  Alert,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Context as AuthContext } from '../../../context/AuthContext';

const SignUpConfirm = () => {
  const {
    state: authState,
    signUpConfirm,
    clearError,
  } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [modal, setModal] = useState(true);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (authState.signUpConfirm === false) {
      clearError();
      setloading(false);
      setModal(!modal);
      history.push('/login');
    }
  }, [authState.signUpConfirm]);

  useEffect(() => {
    if (authState.user.signInUserSession) {
      clearError();
      setloading(false);
      setModal(!modal);
      history.push('/authhome');
    }
  }, [authState.user]);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
  });

  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {
      history.push('/');
    }
  };

  const submit = (values) => {
    signUpConfirm(values);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('signupTitle')}</ModalHeader>
        <ModalBody>
          {loading
            ? <Spinner color="primary" />
            : <></>}
          <Formik
            initialValues={{
              email: '',
              code: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              handleChange, handleBlur, handleSubmit, touched, values, errors,
            }) => (
              <Form>
                <FormGroup>
                  <Label for="Email">{t('email')}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="Email"
                    placeholder="example@email.com"
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    invalid={errors.email && touched.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="code">{t('signUpCode')}</Label>
                  <Input
                    type="code"
                    name="text"
                    id="code"
                    autoComplete="off"
                    value={values.code}
                    onChange={handleChange('code')}
                    onBlur={handleBlur('code')}
                    invalid={errors.code && touched.code}
                  />
                  <FormFeedback>{errors.code}</FormFeedback>
                </FormGroup>
                <FormGroup className="float-end mt-4">
                  <Button color="primary" onClick={handleSubmit}>{t('signup')}</Button>
                  {' '}
                  <LinkContainer to="/">
                    <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                  </LinkContainer>
                </FormGroup>
                <FormGroup className="mt-2">
                  {authState.errorMessage
                    ? <Alert color="danger">{authState.errorMessage}</Alert>
                    : <></>}
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SignUpConfirm;
