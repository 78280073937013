import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Context as AuthContext } from '../../context/AuthContext';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const { i18n, t } = useTranslation();
  const {
    state: authState,
    logout,
    currentUser,
  } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (authState.user.signInUserSession) {
      setisLoggedIn(true);
    } else {
      setisLoggedIn(false);
    }
  }, [authState.user]);

  useEffect(() => {
    currentUser();
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  const logoutUser = () => {
    logout();
    setisLoggedIn(false);
    setIsOpen(false);
    history.push('/');
  };

  const buildAuthSection = () => {
    if (isLoggedIn) {
      return (
        <NavItem>
          <NavLink href="#" onClick={() => { logoutUser(); }}>{t('logout')}</NavLink>
        </NavItem>
      );
    }
    return (
      <>
        <NavItem>
          <LinkContainer to="/login">
            <NavLink href="#" onClick={() => { setIsOpen(false); }}>{t('login')}</NavLink>
          </LinkContainer>
        </NavItem>
      </>
    );
  };

  const buildAccountSection = () => {
    if (isLoggedIn) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {t('account')}
          </DropdownToggle>
          <DropdownMenu right>
            <LinkContainer to="/accountsettings">
              <DropdownItem onClick={() => setIsOpen(false)}>
                {t('accountSetings')}
              </DropdownItem>
            </LinkContainer>
            <LinkContainer to="/changepassword">
              <DropdownItem onClick={() => setIsOpen(false)}>
                {t('accountPassword')}
              </DropdownItem>
            </LinkContainer>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return (
      <>
      </>
    );
  };

  const buildBrand = () => {
    let ref = '/';
    if (isLoggedIn) {
      ref = '/authhome';
    }
    return (
      <NavbarBrand className="mr-auto" href={ref} onClick={() => { setIsOpen(false); }}>
        {t('appName')}
      </NavbarBrand>
    );
  };

  return (
    <Navbar light>
      <LinkContainer to="/">
        {buildBrand()}
      </LinkContainer>
      <NavbarToggler className="mr-2" onClick={() => { setIsOpen(!isOpen); }} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {buildAuthSection()}
          {buildAccountSection()}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {t('language')}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => changeLanguage('en')}>
                {t('english')}
              </DropdownItem>
              <DropdownItem onClick={() => changeLanguage('fr')}>
                {t('french')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavBar;
