import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Jumbotron } from 'reactstrap';

import { Context as AuthContext } from '../../context/AuthContext';

const Home = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state: authState, currentUser } = useContext(AuthContext);

  useEffect(() => {
    currentUser();
  }, []);

  useEffect(() => {
    if (authState.user.signInUserSession) {
      history.push('/authhome');
    }
  }, [authState.user]);

  return (
    <Col sm="12" md={{ size: 6, offset: 4 }}>
      <Jumbotron>
        <h1>{t('welcome')}</h1>
      </Jumbotron>
    </Col>
  );
};

export default Home;
