import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Jumbotron } from 'reactstrap';

import { Context as AuthContext } from '../../../context/AuthContext';

const AccountSettings = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state: authState } = useContext(AuthContext);
  const [name, setName] = useState('');

  useEffect(() => {
    if (authState.user.signInUserSession) {
      setName(authState.user.attributes.name);
    } else {
      history.push('/');
    }
  }, [authState.user]);

  return (
    <Col sm="12" md={{ size: 6, offset: 4 }}>
      <Jumbotron>
        <h1>{`${t('accountSetings')} - ${name}`}</h1>
      </Jumbotron>
    </Col>
  );
};

export default AccountSettings;
