import { Auth } from 'aws-amplify';

import CreateContext from './CreateDataContext';

const authReducer = (state, action) => {
  const values = {
    user: state.user,
    signUpConfirm: false,
    changePassword: false,
    errorMessage: null,
  };
  switch (action.type) {
    case 'login':
      if (action.payload.signUpConfirm) {
        values.signUpConfirm = action.payload.signUpConfirm;
      }
      if (action.payload.changePassword) {
        values.changePassword = action.payload.changePassword;
      }
      if (action.payload.user) {
        values.user = action.payload.user;
      }
      return {
        ...state,
        ...values,
      };
    case 'logout':
      return {
        ...state,
        ...values,
      };
    case 'signup':
      values.signUpConfirm = true;
      values.user = action.payload.user;
      return {
        ...state,
        ...values,
      };
    case 'confirmSignUp':
      return {
        ...state,
        ...values,
      };
    case 'forgotPassword':
      values.changePassword = true;
      return {
        ...state,
        ...values,
      };
    case 'forgotPasswordConfirm':
      return {
        ...state,
        ...values,
      };
    case 'currentUser':
      return {
        ...state,
        ...values,
      };
    case 'error':
      return {
        ...state,
        errorMessage: action.payload,
      };
    case 'clear_error':
      return { ...state, errorMessage: null };
    default:
      return state;
  }
};

const login = (dispatch) => async (values) => {
  try {
    const { email, password } = values;
    const user = await Auth.signIn(email, password);
    console.log(user);
    dispatch({ type: 'login', payload: { user } });
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      dispatch({ type: 'login', payload: { signUpConfirm: true } });
    } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      dispatch({ type: 'login', payload: { changePassword: true } });
    } else {
      dispatch({ type: 'error', payload: err.message });
    }
  }
};

const logout = (dispatch) => async () => {
  try {
    await Auth.signOut();
    dispatch({ type: 'logout', payload: { } });
  } catch (err) {
    dispatch({ type: 'error', payload: err.message });
  }
};

const signUp = (dispatch) => async (values) => {
  try {
    const {
      email,
      password,
      givenName,
      familyName,
      phone,
    } = values;
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: givenName,
        family_name: familyName,
        name: `${givenName} ${familyName}`,
        phone_number: phone,
      },
    });
    console.log(user);
    dispatch({ type: 'signup', payload: { user } });
  } catch (err) {
    dispatch({ type: 'error', payload: err.message });
  }
};

const signUpConfirm = (dispatch) => async (values) => {
  try {
    const { email, code } = values;
    await Auth.confirmSignUp(email, code);
    dispatch({ type: 'confirmSignUp', payload: {} });
  } catch (err) {
    dispatch({ type: 'error', payload: err.message });
  }
};

const forgotPassword = (dispatch) => async ({
  email,
}) => {
  try {
    await Auth.forgotPassword(email);
    dispatch({
      type: 'forgotPassword',
      payload: {
        email,
      },
    });
  } catch (err) {
    dispatch({ type: 'error', payload: err.message });
  }
};

const forgotPasswordConfirm = (dispatch) => async ({ email, password, code }) => {
  try {
    Auth.forgotPasswordSubmit(email, code, password);
    dispatch({ type: 'forgotPasswordConfirm' });
  } catch (err) {
    dispatch({ type: 'error', payload: err.message });
  }
};

const currentUser = (dispatch) => async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      dispatch({ type: 'currentUser', payload: { user } });
    }
  } catch {
    console.log('No current user');
  }
};

const clearError = (dispatch) => async () => {
  dispatch({ type: 'error', payload: null });
};

export const { Context, Provider } = CreateContext(
  authReducer,
  {
    login,
    logout,
    signUp,
    signUpConfirm,
    forgotPassword,
    forgotPasswordConfirm,
    currentUser,
    clearError,
  },
  {
    user: {},
    signUpConfirm: false,
    forgotPasswordConfirm: false,
    changePassword: false,
    errorMessage: null,
  },
);
